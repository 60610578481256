import styled from 'styled-components';

const Detail = () => {
  return <Wrap>상세현황</Wrap>;
};

export default Detail;
const Wrap = styled.div`
  margin-top: 100px;
`;
