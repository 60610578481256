import styled from 'styled-components';

const Dashboard = () => {
  return <Wrap>대시</Wrap>;
};

export default Dashboard;

const Wrap = styled.div`
  margin-top: 100px;
`;
