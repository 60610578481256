import styled from 'styled-components';

const Stock = () => {
  return <Wrap>재고 관리</Wrap>;
};

export default Stock;
const Wrap = styled.div`
  margin-top: 100px;
`;
